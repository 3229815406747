<template>
	<transition name="slide-fade" mode="out-in">
		<Layout>
			<div v-if="registered">
				<Logo />
				<div class="mb-5 text-center auth__waiting">
					<h1 class="has-text-primary is-size-4 is-bold mb-4">{{ $t('auth.done') }}</h1>
					<p v-html="$t('auth.waiting')"></p>
					<p class="is-bold mt-4">{{ $t('auth.thanks') }}</p>
				</div>
			</div>
			<ValidationObserver v-else ref="observer" v-slot="{ handleSubmit }">
				<form class="auth__form" @submit.prevent="handleSubmit(createUser)">
					<Logo />
					<div class="mb-5 text-center">
						<h1 class="has-text-primary is-size-4 is-bold">{{ $tc('auth.be_producer') }}</h1>
						<!-- prettier-ignore -->
						<p>{{ $t('auth.have_account') }} <router-link id="link-login" :to="{ name: 'Login' }" class="is-semibold">{{ $t('auth.back_to_login') }}</router-link></p>
					</div>

					<div class="columns">
						<div class="column pl-0">
							<InputWithValidation tab="1" rules="required|min:2" name="firstname" :label="$t('fields.first_name')" size="is-medium" v-model="auth.first_name" />
						</div>
						<div class="column pr-0">
							<InputWithValidation tab="2" rules="required|min:2" name="lastname" :label="$t('fields.last_name')" size="is-medium" v-model="auth.last_name" />
						</div>
					</div>

					<InputWithValidation tab="3" rules="required|email" field="email" name="email" :label="$t('fields.email')" size="is-medium" v-model="auth.email" />

					<div class="columns">
						<div class="column pl-0">
							<InputWithValidation tab="4" v-mask="'(##) ####-####'" name="phone" :label="$t('fields.phone')" size="is-medium" v-model="auth.phone" />
						</div>
						<div class="column pr-0">
							<InputWithValidation tab="5" name="whatsapp" v-mask="'(##) #####-####'" :label="$t('fields.whats')" size="is-medium" v-model="auth.whatsapp" />
						</div>
					</div>

					<div class="columns">
						<div class="column pl-0">
							<InputWithValidation tab="6" v-mask="'###.###.###-###'" rules="required" name="document" :label="$t('fields.document')" size="is-medium" v-model="auth.document" />
						</div>
						<div class="column pr-0">
							<InputWithValidation tab="7" v-mask="'##/##/####'" name="birth_date" :label="$t('fields.birthdate')" size="is-medium" v-model="auth.birth_date" />
						</div>
					</div>

					<InputWithValidation tab="8" rules="required|min:8" field="password" name="password" :label="$t('fields.password')" vid="password" size="is-medium" password-reveal v-model="auth.password" />

					<password-meter class="mb-2" :password="auth.password" @score="Score" />

					<div class="field">
						<label class="label">{{ $tc('fields.docs', 2) }}</label>
					</div>
					<b-upload v-model="documents" multiple drag-drop>
						<section class="p-3">
							<div class="content has-text-centered">
								<b-icon icon="upload" size="is-large"></b-icon>
								<p v-html="$t('auth.upload_docs')"></p>
							</div>
						</section>
					</b-upload>
					<div class="mt-2 tags">
            <span v-for="(file, index) in documents" :key="index" class="tag is-primary" >
              {{ file.name }}
              <button class="delete is-small" type="button" @click="removeDocument(index)"></button>
            </span>
        	</div>

					<b-checkbox type="is-primary" rules="required" v-model="auth.terms" name="terms" checked>
						<span>Li e aceito os <a :href="`${site_url}termos-de-uso`" target="_blank">termos de uso</a> e a <a :href="`${site_url}politica-de-privacidade`" target="_blank">política de privacidade</a>.</span>
					</b-checkbox>

					<span class="is-block text-right mt-3 mb-5">
						<b-button :disabled="!auth.terms" id="button-register" tabindex="9" native-type="submit" class="button is-button is-primary mb-5" :loading="loading">{{ $t('buttons.send') }}</b-button>
					</span>
				</form>
			</ValidationObserver>
		</Layout>
	</transition>
</template>

<script>
import Layout from '@/layouts/Auth'
import Logo from '@/components/Logo'
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import PasswordMeter from 'vue-simple-password-meter'
import { ToastProgrammatic as Toast } from 'buefy'
import { mapActions } from 'vuex'
import Api from '@/services/api'

export default {
	components: {
		Layout,
		Logo,
		InputWithValidation,
		ValidationObserver,
		PasswordMeter
	},
	data() {
		return {
			loading: false,
			registered: false,
			documents: [],
			auth: {
				first_name: '',
				last_name: '',
        email: '',
        phone: '',
        whatsapp: '',
				document: '',
        birth_date: '',
				password: '',
        terms: true
			},
			site_url: process.env.VUE_APP_SITE_URL
		}
	},
	methods: {
		...mapActions('user', [
			'getUserData'
		]),
		removeDocument(index) {
			this.documents.splice(index, 1)
		},
		async createUser() {
			try {
				this.loading = true

                let formData = new FormData()
                formData.append('first_name', this.auth.first_name)
				formData.append('last_name', this.auth.last_name)
                formData.append('email', this.auth.email)
                formData.append('phone', this.auth.phone)
                formData.append('whatsapp', this.auth.whatsapp)
				formData.append('document', this.auth.document)
                formData.append('birth_date', this.auth.birth_date)
                formData.append('password', this.auth.password)
                formData.append('terms', this.auth.terms)
                formData.append('register', 1)

                this.documents.forEach((d) => {
                    formData.append('documents[]', d)
                })

				const response = await Api.post('user/register', formData)
				if (response.status === 201) {
					this.registered = true
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					Toast.open({
						message,
						type: 'is-danger',
						position: 'is-bottom'
					})
				}
			} finally {
				this.loading = false
			}
		},
		Score({ score, strength }) {
			console.log(score, strength) // score: from 0 to 4 - strength: 'risky', 'guessable', 'weak', 'safe' , 'secure'
			this.score = score
		}
	}
}
</script>
